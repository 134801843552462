import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IonApp, IonSplitPane, IonPage, IonAlert, IonContent } from '@ionic/react';
import { Router } from 'react-router-dom';
import { Plugins, Capacitor } from '@capacitor/core';
import { getConfig } from './appConfig';
import ProtectedRoute from './components/protectedRoute';
import history from './history';
import { getDefaultRoute, getPageClassName, isWebConfig } from './lib/utils';
import { withTranslation } from '../src/lib/translate';
import {
  init,
  changeConnectionStatus,
  setMyLocation,
  setCommonModal,
  updateProfile,
} from './store/actions';
import ValidateModal from './components/validateModal';
import StaticHeader from './components/staticHeader';
import Drawer from './components/drawer';
import Toast from './components/toast';
import Loading from './components/spinner';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import mobiscroll from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
/* Theme */
require('./theme/' + (isWebConfig() ? 'web' : 'index') + '.css');

const { Network, Geolocation } = Plugins;

Plugins.App.addListener('backButton', () => {
  if (
    history.location &&
    history.location.pathname &&
    getConfig().general.appExitRoutes.indexOf(history.location.pathname) !== -1
  ) {
    Plugins.App.exitApp();
  } else {
  }
});

const mapRoutes = (routes, extraProps = {}) => {
  return routes
    .filter((route) => !!route.path && !!route.component)
    .map((item) => {
      const { path } = item;
      const ActualRoute = item.protected ? ProtectedRoute : Route;
      return (
        <ActualRoute
          exact={item.exact}
          path={path}
          key={'nav-key-' + path}
          route={item}
          cmp={item.component}
          render={(props) =>
            item.render ? (
              item.render({ ...props, ...extraProps, route: item })
            ) : (
              <item.component {...props} {...extraProps} route={item} />
            )
          }
        />
      );
    });
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.defaultRoute = [];
    this.routeComponents = [];
    this.authRouteComponents = [];
    this.additionalRouteComponents = [];
    this.notInMenuRouteComponents = [];

    this.content = null;
  }

  componentDidMount() {
    this.props.dispatch(init());

    Network.addListener('networkStatusChange', (conStatus) => {
      const status = conStatus.connected;
      this.props.dispatch(changeConnectionStatus(status));
    });
    this.getCurrentPosition();
  }

  async getCurrentPosition() {
    const { myLocation, dispatch } = this.props;
    if (!myLocation.latitude && !myLocation.longitude) {
      // this.showModal(true)
      try {
        const coordinates = await Geolocation.getCurrentPosition({
          enableHighAccuracy: false,
        });
        const myLocation = {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
        };
        dispatch(setMyLocation(myLocation));
      } catch (error) {
        if (
          JSON.stringify(error).indexOf('kCLErrorDomain') !== -1 &&
          JSON.stringify(error).indexOf('error 0') !== -1
        ) {
          this.getCurrentPosition();
        }
      }
    }
  }

  onRemoveValidateModal = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isValidationModalOpen', false));
    dispatch(updateProfile({ is_verification_pop_up_shown: true }, true));
  };

  onRemoveSnoozedSkusFromBasketModal = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isRemoveSnoozedSkusFromBasketModalOpen', false));
  };

  generateRoutes = () => {
    this.defaultRoute = getDefaultRoute(this.props.navConfig);
    this.routeComponents = mapRoutes(this.props.navConfig.routes);
    this.authRouteComponents = mapRoutes(this.props.navConfig.authRoutes);
    this.additionalRouteComponents = mapRoutes(this.props.navConfig.additionalRoutes);
    this.notInMenuRouteComponents = mapRoutes(this.props.navConfig.notInMenuRoutes);
  };

  render() {
    const {
      __,
      isValidationModalOpen,
      initLoading,
      isRemoveSnoozedSkusFromBasketModalOpen,
      isRemoveSnoozedSkusFromBasketModalOpen_data,
    } = this.props;
    if (Capacitor.platform === 'web') {
      // mobi scroll style for desktop
      mobiscroll.settings = {
        theme: 'ios',
        themeVariant: 'light',
      };
      // wait for init saga to finish (reason: we dont have splash screen in web)
      if (!initLoading) {
        this.generateRoutes();
        this.content = null;
      } else {
        this.content = (
          <IonPage id="main">
            <IonContent>
              <Loading additionalLoadingCondition />
            </IonContent>
          </IonPage>
        );
      }
    } else {
      // mobile
      if (!initLoading) {
        this.generateRoutes();
        this.content = null;
      }else {
        this.content = (
          <IonPage id="main">
            <IonContent>
              <Loading additionalLoadingCondition />
            </IonContent>
          </IonPage>
        );
      }
    }

    return (
      <IonApp className={isWebConfig() ? 'web' : ''}>
        <Router history={history}>
          {this.content ? (
            this.content
          ) : (
            <IonSplitPane contentId="main">
              <Drawer />
              <IonPage id="main">
                <Switch>
                  {this.routeComponents}
                  {this.authRouteComponents}
                  {this.additionalRouteComponents}
                  {this.notInMenuRouteComponents}
                  {this.defaultRoute ? (
                    <Route exact path="/" render={() => <Redirect to={this.defaultRoute.path} />} />
                  ) : null}
                </Switch>
                <Toast />
                <ValidateModal />
                <IonAlert
                  isOpen={isValidationModalOpen}
                  onDidDismiss={this.onRemoveValidateModal}
                  header={__('Success')}
                  message={__('The app is now unlocked to redeem your loyalty')}
                  buttons={[
                    {
                      text: __('Close'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onRemoveValidateModal,
                    },
                  ]}
                />
                <IonAlert
                  isOpen={isRemoveSnoozedSkusFromBasketModalOpen}
                  onDidDismiss={this.onRemoveSnoozedSkusFromBasketModal}
                  header={__('Warning')}
                  message={
                    __(isRemoveSnoozedSkusFromBasketModalOpen_data.fixedText || '') +
                    '<br/>' +
                    (isRemoveSnoozedSkusFromBasketModalOpen_data.items || [])
                      .map((i, index) => index + 1 + '. ' + i.productName)
                      .join('<br/>')
                  }
                  buttons={[
                    {
                      text: __('Close'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onRemoveSnoozedSkusFromBasketModal,
                    },
                  ]}
                />
              </IonPage>
            </IonSplitPane>
          )}
        </Router>
      </IonApp>
    );
  }
}

const stateToProps = (store) => {
  const { auth, profile } = store.profile;
  const {
    myLocation,
    initLoading,
    isRemoveSnoozedSkusFromBasketModalOpen,
    isRemoveSnoozedSkusFromBasketModalOpen_data,
    navConfig,
  } = store.common;
  return {
    auth,
    myLocation,
    profile,
    initLoading,
    navConfig,
    isRemoveSnoozedSkusFromBasketModalOpen,
    isRemoveSnoozedSkusFromBasketModalOpen_data: isRemoveSnoozedSkusFromBasketModalOpen_data || {},
  };
};

export default connect(stateToProps)(withTranslation(App));
