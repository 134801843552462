import React, { Component } from 'react';
import { IonList, IonItem, IonIcon } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { forwardTo } from '../../lib/utils';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import { addCircleOutline } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import { SmallText, StrongText } from '../../components/common';

class TileOrderList extends Component {
    constructor(props) {
        super(props);
        this.ionList = null;
        this.orderListItems = null;
    }
    componentDidMount() {
        const item = document.querySelector('.order-list-items');
        const itemList = document.querySelector('.order-list-items > ion-list');
        if (item && itemList) {
            this.ionList = itemList;
        }
    }

    itemList = (__, item, index) => {
        const { profile } = this.props;
        if (item !== null) {
            let image =
                item.itemRichData && item.itemRichData.squareImageUrl
                    ? item.itemRichData.squareImageUrl
                    : '';
            if (image && image.indexOf('http://') !== -1) {
                image = image.replace(/http:\/\//g, 'https://');
            }
            item.selectedMenu = this.props.selectedMenu || [];
            return (
                <IonItem
                className={!Basket.isProductUnsnoozed(item) ? 'disabled-element' : ''}

                    key={index}
                    lines="none"
                    onClick={() =>
                        Basket.isProductUnsnoozed(item) ? forwardTo('/item-details', item) : null
                      }
                >
                    <div tabIndex="-1" className="order-list-item grid-order-list-item">
                      
                        <div className="item-price">
                            <SmallText>{Basket.formatPrice(item.productPrice)}</SmallText>
                            {item.originalPrice ? (
                                <>
                                    <br />
                                    <SmallText className="original-price">&#163;{item.originalPrice}</SmallText>
                                </>
                            ) : null}
                        </div>
                        <div className="item-content">
                            <StrongText>{__(Basket.getProductName(item, profile))}</StrongText>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: Basket.getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, ''),
                                }}
                            />
                        </div>

                        <div className="item-image-wrapper">
                            <div
                                className="item-image"
                                style={image && image !== '' ? { backgroundImage: `url(${image})` } : {}}
                            />
                        </div>
                    </div>
                </IonItem>
            );
        }
    };
    setBackgroundImage = (category) => {
        if (category) {
            if (!category.menuEntry) {
                let image = category.itemRichData?.rawImageUrl;
                if (image && image.indexOf('http://') !== -1) {
                    image = image.replace(/http:\/\//g, 'https://');
                }
                return { backgroundImage: `url(${image})` };
            }
            const item = category.menuEntry.find((el) => el.itemRichData && el.itemRichData.rawImageUrl);
            if (item) {
                let image = item.itemRichData.rawImageUrl;
                if (image && image.indexOf('http://') !== -1) {
                    image = image.replace(/http:\/\//g, 'https://');
                }
                return { backgroundImage: `url(${image})` };
            }
            return this.setBackgroundImage(category.menuEntry[0]);
        }
    };

    drawTiles(__, data) {
        const categories = data.menuEntry || data.menuEntryGroups || [];
        return categories.map((el, index) => {
            if (el['@type'] === 'group') {
                return (
                    <div
                        className={
                            getConfig().menuDisplayType == 3
                                ? 'order-categories-two-columns-tiles-wrapper'
                                : 'order-categories-tiles-wrapper'
                        }
                        key={index}
                    >
                        <div
                            className="order-categories-tile"
                            style={this.setBackgroundImage(el)}
                            onClick={(e) => {
                                this.props.selectMenu(index);
                                this.ionList.parentElement.parentElement.scrollIntoView();
                            }}
                        >
                            <span className="order-categories-tile-title">{el?.name.toLowerCase()}</span>
                        </div>
                    </div>
                );
            } else {
                return this.itemList(__, el, index);
            }
        });
    }

    render() {
        const { __, category } = this.props;

        return (
            <div className="order-list-items" id="order-list-items">
                <p className="category-title">{category.name || null}</p>
                <IonList lines="none">{this.drawTiles(__, category)}</IonList>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        profile: store.profile.profile,
        restaurantsUpdated: store.restaurants.restaurantsUpdated,
        basketUpdated: store.orders.basketUpdated,
    };
};

export default connect(mapStateToProps)(withTranslation(TileOrderList));
