import React from 'react';
import { forwardTo } from '../../lib/utils';

import './index.css';

const PromoBar = ({ __,type, onDismiss, vouchers }) => {
  


  return vouchers && vouchers.length > 0 && vouchers[0].reward ? (
    <div className='promo-bar-wrapper'>
      <div className="promo-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
      <div
        className={'promo-bar ' + (type || 'info')}
        onClick={() => forwardTo('/loyalty', { openVoucherModal: vouchers[0].id })}
      >
        <div className="promo-bar-body ellipsis">
          {__(vouchers[0].reward.small_print)}
        </div>
      </div>
    </div>
  ) : null;
};

export default PromoBar;
