import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonList, IonItem, IonTextarea } from '@ionic/react';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { validateForm, forwardTo } from '../../lib/utils';
import { addDeliveryAddress, storeDeliveryAddress, showToast } from '../../store/actions';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';

import './index.css';

const { getDeliveryAddress, getDeliveryPrice, getMinOrder } = Basket;

class DeliveryAddressAdd extends React.Component {
  state = {
    form: {
      addressLine1: getDeliveryAddress()?.addressLine1 || '',
      addressLine2: getDeliveryAddress()?.addressLine2 || '',
      place: getDeliveryAddress()?.place || '',
      postalCode: getDeliveryAddress()?.postalCode || '',
      notes: '',
      orderType: Basket.getOrderType()?.toLowerCase(),
    },
    formErrors: {},
  };
  formConfig = {};

  handleInput = (key, val) => {
    const form = {
      ...this.state.form,
      [key]: val,
      orderType: this.state.orderType,
    };
    this.setState({
      form,
      formErrors: validateForm(this.formConfig, form),
    });
  };
  save = () => {
    const { __, auth, profile, dispatch } = this.props;
    const orderType = Basket.getOrderType();
    if (auth && auth.loggedIn) {
      let found = !!(orderType === 'charter-delivery'
        ? profile.charter_delivery_address_list
          ? profile.charter_delivery_address_list
          : []
        : profile.address_list
          ? profile.address_list
          : []
      ).find(
        (al) =>
          this.state.form.addressLine1 + this.state.form.place + this.state.form.postalCode ===
          al.addressLine1 + al.place + al.postalCode,
      );

      if (!found) {
        this.props.dispatch(
          addDeliveryAddress({
            ...this.state.form,
            order_type: Basket.getOrderType().toLowerCase(),
          }),
        );
      } else {
        dispatch(showToast(__('Address already exist'), 'warning'));
      }
      Basket.setDeliveryAddress(this.state.form);
    } else {
      this.props.dispatch(storeDeliveryAddress(this.state.form));
      Basket.setDeliveryAddress(this.state.form);
    }
    forwardTo('/delivery-time');
  };

  componentDidMount() {
    this.setState({
      formErrors: validateForm(this.formConfig, this.state.form),
    });
  }

  render() {
    const { __ } = this.props;
    const { form } = this.state;
    const deliveryAddress = getDeliveryAddress();
    const deliveryPrice = getDeliveryPrice();
    const minOrder = getMinOrder();
    const restaurantPostalCode =
      Basket.getRestaurant() !== null
        ? Basket.getRestaurant().postcode
        : deliveryAddress?.postalCode;

    return (
      <Loading transparent>
        <Layout>
          <div className="flex-row-wrapper absolute-content">
            <div className="flex-min">
              <Title>{__('Confirm Delivery')}</Title>
              <Spacer />
              <SmallText className="heading">
                {`${__('Ordering at')} 
                ${deliveryAddress?.place} ${__('for delivery to')} ${deliveryAddress?.addressLine1}, ${deliveryAddress?.addressLine2 ? `${deliveryAddress?.addressLine2},`:''} ${Basket.getRestauranName()}, ${restaurantPostalCode}`}
              </SmallText>
              <Spacer />
              <SmallText className="heading">
                {deliveryPrice === 0 || deliveryPrice == "" ? `${__('Free Delivery')}. ` : `${__('Delivery charge:')} ${deliveryPrice}.`}
              </SmallText>{' '}
              <SmallText className="heading">
                {minOrder === 0 ? null : __(`Minimum order: ${Basket.formatPrice(minOrder)}`)}
              </SmallText>
              <Spacer />
            </div>
            <div className="scrollable-y driver-notes">
              <IonList lines="full">
                <IonItem>
                  <SmallText className="heading">{__('Driver notes (optional)')}</SmallText>
                  <IonTextarea
                    value={form.notes}
                    onIonChange={(e) => this.handleInput('notes', e.target.value)}
                    type="text"
                    clearInput
                  />
                </IonItem>
              </IonList>
            </div>
            <div className="flex-min">
              <Spacer />
              {/* <IonButton disabled={ !formValid } expand="block" color="secondary" onClick={ this.save }>{ __('Save Address & Continue')}</IonButton> */}
              <IonButton expand="block" color="primary" onClick={this.save}>
                {__('Continue ')}
              </IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  const { deliveryAddress, storedDeliveryAddress } = state.orders;
  return {
    profile,
    auth,
    deliveryAddress,
    storedDeliveryAddress,
    isLoggedIn: profile.auth && profile.auth.loggedIn,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryAddressAdd));
