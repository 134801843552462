import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBadge,
} from '@ionic/react';
import Layout from '../../components/layout';
import PromoBar from '../../components/promoBar';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Subtitle, Title } from '../../components/common';


const { hasOrdering, hasLoyalty, hasCampaignManager } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
    };
  }

  render() {
    const { __, screenName, vouchers } = this.props;
    const { singleDelivery } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
      return false;
    });
    return (
      <Layout hideSecondToolbar={true} color="transparent" noPadding={true}>
        <div className="absolute-content dash-layout"></div>
        {hasCampaignManager ? <PromoBar __={__} type={__('info')} vouchers={voucherRes} /> : null}
        <div className='dashboard-content'>
          <div className='dashboard-header' style={voucherRes && voucherRes.length > 0 && voucherRes[0].reward ? null: {paddingTop:'10px'} }>
            <Subtitle className='uppercase bold letter-spacing-big'>{__('Loyalty')}</Subtitle>
            <Title>{__('Welcome')}{' '}{screenName}</Title>
            <NormalText>{__(getConfig().general.dashboardText)}</NormalText>
          </div>
          <div className='dashboard-cards-wrapper'>
            <div className='dashboard-card loyalty' onClick={() => forwardTo('/loyalty')}>
              <Subtitle>{__('Your Rewards')}</Subtitle>
            </div>
            <div className='dashboard-card order' onClick={() => forwardTo('/delivery-options')}>
            <Subtitle>{__('Start New Order')}</Subtitle>
            </div>
            <div className='dashboard-card refer'onClick={() => forwardTo('/refer-a-friend')} >
            <Subtitle>{__('Refer A Friend')}</Subtitle>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers } = state.profile;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name}`;
  }
  return {
    screenName,
    vouchers: vouchers || [],
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
