import React from 'react';
import { IonButton } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { isDefined, isWebConfig } from '../../lib/utils';
import Layout from '../../components/layout';
import { Title, Spacer, Sectiontitle, StrongText } from '../../components/common';
import Basket from '../../lib/basket';
import './index.css';
import { PillGroup } from '../../components/pill';
const { getItems, getAllergen } = Basket;
class ApplyAllergensRaw extends React.Component {

  ApplyAllergens = () => {
    if (isWebConfig() && this.props.ApplyAllergensModalOpen) {
      this.props.handleAllergensModal(false);
    } else {
      this.props.history.push('/order-summary');
    }
  };

  render() {
    const { __ } = this.props;
    const allergens = getAllergen() || [];

    return (
      <div className="absolute-content flex-row-wrapper allergens-wrapper">
        <div className="scrollable-y rhino">
          <Title className='web-only'>{__('Allergen Information')}</Title>
          {getItems().map((item, index) => {
            let allAllergens = [];
            let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
            if (data && data.length > 0) {
              allAllergens.push(
                <div key={'allergen-' + index}>
                  <StrongText className='bold'>
                    {item.quantity} x {item.item.productName}
                  </StrongText>
                  <PillGroup
                    items={data[0].allergens}
                    // borderColor="primary"
                    // color="primary"
                    headerTitle="Order Summary"
                    paddingTop={4}
                  />
                  <Spacer />
                </div>,
              );
            }
            // check allergens for sub-items
            if (isDefined(item.selectedChoices)) {
              item.selectedChoices.forEach((group) => {
                group.forEach((item) => {
                  if (
                    isDefined(item.itemRichData) &&
                    isDefined(item.itemRichData.allergenCodes) &&
                    item.itemRichData.allergenCodes.length > 0
                  ) {
                    let data1 = allergens.find((allergen) => allergen[1].sku === item.sku);
                    if (data1 && data1.length > 0) {
                      allAllergens.push(
                        <div key={'allergen_subitem_' + index + '_' + item.productName}>
                          <Sectiontitle>{item.productName}</Sectiontitle>
                          <PillGroup items={data1[0].allergens} borderColor="primary" />
                          <Spacer />
                        </div>,
                      );
                    }
                  }
                });
              });
            }
            return allAllergens;
          })}
        </div>
        <div className="flex-min">
          <Spacer />
          <IonButton
            expand="block"
            color="secondary"
            onClick={() => this.ApplyAllergens(false)}
          >
            {__('Close')}
          </IonButton>
        </div>
      </div>
    );
  }
}

export const ApplyAllergens = withTranslation(ApplyAllergensRaw);

const AllergensWrapped = (props) => (
  <Loading>
    <Layout headerWithTitle={true} color="transparent" hideSecondToolbar={true} headerTitle={'Allergen Information'}>
      <ApplyAllergens {...props} />
    </Layout>
  </Loading>
);

export default withTranslation(AllergensWrapped);
