import React from 'react';
import { IonCard, IonCardContent, IonButton, IonInput, IonItem } from '@ionic/react';

import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, Spacer, FieldError, SmallText, NormalText } from '../../components/common';
import { validateForm } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendRefer } from '../../store/actions';
import referIcon from '../../assets/images/icons/refer-a-friend.svg'
import './index.css';

class ReferAFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      formErrors: {},
    };
    this.formConfig = {
      email: { type: 'email', required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleRefer = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { email } = this.state;
      const data = { email: email };
      this.setState({ email: '' });
      dispatch(sendRefer(data));
    }
  };

  render() {
    const { __ } = this.props;
    const { email } = this.state;
    return (
      <Loading transparent>
        <Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Refer a Friend')}>
          <div className="absolute-content" title="Refer a friend"></div>
          <img src={referIcon} />
          <Spacer />
          <Title>{__('Refer a Friend')}</Title>
          <NormalText>{__(
            'Once your friend has signed up and used the app you will both receive a reward of 10 points.')}</NormalText>
          <div>
            <Spacer size={1} />

            <IonItem lines='none' className='input-field-wrapper'>
              <IonInput
                className="refer-fiend-email"
                onIonChange={(e) => this.handleInput('email', e.target.value)}
                required
                placeholder={__("Enter your friend's email")}
                type="email"
                pattern="email"
                inputmode="email"
                value={email}
              ></IonInput>
            </IonItem>
            <FieldError className="field-error" value={__(this.state.formErrors.email)} />
            <Spacer size={2} />
            <IonButton
              expand="block"
              color="secondary"
              strong={true}
              onClick={() => this.handleRefer()}
            >
              {__('Send Invitation')}
            </IonButton>
          </div>

        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(ReferAFriend);
